import React from "react";
import { graphql } from "gatsby";
import { useGlobal } from "reactn";
import Img from "gatsby-image";


import NewSection from "../contentModels/NewSection/NewSection";
import SEO from "../components/seo";
import App from "../pages/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import getLang from "../components/scripts/getLang";
import * as Style from "../css/templateStyle";
import * as Global from "../css/globalStyle";
import facebookIcon from "../assets/icons/facebook.svg";
import twitterIcon from "../assets/icons/twitter.svg";

const NewsPost = ({ data }) => {
  let [english] = useGlobal("English");

  data = getLang(english, data.allContentfulNyheter);

  const {
    rubrik,
    frstaKolumnen,
    andraKolumnen,
    brdtext,
    huvudBild,
    knappText,
    knapplnk,
    delaPTwitterlnk,
    delaPFacebooklnk,
    seoTitel,
    seoDescription,
    nyhetsDatum,
    contentSections,
  } = data[0].node;

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Style.shortContainer className="newsPost">
        <Style.ArrowBack color={"black"}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => window.history.back()}
          />
        </Style.ArrowBack>
        <Img fluid={huvudBild.fluid} />

        <Style.PostContent>
          <h1 style={{display:'grid'}} className={"h1"}> <span style={{fontSize:'15px'}}>{nyhetsDatum ? nyhetsDatum.replace(/-/g, '.') : null}</span> {rubrik} </h1>
          <div style={{width:'100%'}} className={"NewsWidth"}
              dangerouslySetInnerHTML={{
                __html: brdtext.childContentfulRichText.html
              }}
            />
          <Style.ContentContainer>
            {frstaKolumnen && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: frstaKolumnen.childContentfulRichText.html
                  }}
                />
              )}

              {andraKolumnen && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: andraKolumnen.childContentfulRichText.html
                  }}
                />
              )}
            </Style.ContentContainer>
          {delaPFacebooklnk || delaPTwitterlnk ? (
            <Style.NewsIcon>
              {english ? <p>Share article</p> : <p>Dela artikeln</p>}
              <div className="mainContentContainer"></div>
              <div>
                {delaPFacebooklnk ? (
                  <a
                    href={delaPFacebooklnk.delaPFacebooklnk}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebookIcon} alt="FacebookIcon" />
                  </a>
                ) : null}
                {delaPTwitterlnk ? (
                  <a
                    href={delaPTwitterlnk.delaPTwitterlnk}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twitterIcon} alt="TwitterIcon" />
                  </a>
                ) : null}
              </div>
            </Style.NewsIcon>
          ) : null}

          {knappText ? (
            <a style={{ display: 'flex', justifyContent: 'center '}} href={knapplnk}>
              <Global.button>{knappText}</Global.button>
            </a>
          ) : null}

        </Style.PostContent>
      </Style.shortContainer>
      {contentSections?.map((section) => <NewSection {...section} />)}
    </App>
  );
};
export default NewsPost;

export const pageQuery = () => {
  return graphql`
    query ContentfulNewsPostBySlug($slug: String!) {
      allContentfulNyheter(filter: { slug: { eq: $slug } }) {
        edges {
          node {
            node_locale
            rubrik
            seoTitel
            seoDescription
            knappText
            knapplnk
            nyhetsDatum
            huvudBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            contentSections {
              linkSlug
              linkText
              title
              imagePosition
              content {
                json
              }
              image {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
            }
            brdtext {
              childContentfulRichText {
                html
              }
            }
            frstaKolumnen {
              childContentfulRichText {
                html
              }
            }
            andraKolumnen {
              childContentfulRichText {
                html
              }
            }
            delaPTwitterlnk {
              delaPTwitterlnk
            }
            delaPFacebooklnk {
              delaPFacebooklnk
            }
          }
        }
      }
    }
  `;
};
