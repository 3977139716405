import styled from "styled-components";
import { Container, Row, Col } from 'styled-bootstrap-grid'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
`;

export const SectionDescription = styled.div`
  margin: 16px auto;
  @media(max-width: 768px) {
    margin: 0px 3%;
  }
`

export const CustomRow = styled(Row)`
  flex-direction: ${(props => props.imagePosition === 'left' ? 'row-reverse' : 'row')};
  align-items: center;

  @media(max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const CustomCol = styled(Col)`
  @media(max-width: 768px) {
    margin-bottom: 15px;
  }

  @media(max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    h2 {
      margin: 0px 3%;
    }
  }
`